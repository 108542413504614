
    @import "config.scss";
 
.container {
  padding: 10px 20px;
  position: fixed;
  background: var(--color-grey);
  z-index: 1000;
  width: 14rem;
  right: 0;
  // transform: translateX(-50%);
  // top: 7%;
  border: var(--color-primary) 1px solid;
  border-radius: 15px;

  @include for-desktop-up {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 120%;
  }

  .item {
    margin-block: 15px;
    color: var(--color-white-dark);
    display: flex;
    cursor: pointer;
  }

  .items {
    color: var(--color-white-dark);
    p {
      span {
        color: var(--color-primary);
      }
    }
  }

  .icon {
    color: var(--color-white-absolute);
    margin-right: 10px;
  }
}
