
    @import "config.scss";
 
.userIcon {
  position: relative;

  @include for-desktop-up {
    width: 10rem;
    // flex: 1;
  }
}

.online {
  position: absolute;
  top: 0.1rem;
  right: 0;
}
